$font-ja: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
$font-en: "Roboto", sans-serif;

$c-text: #1d1d1d;
$c-border: #505050;
$c-bg-cream: #edebe4;
$c-bg-dark-cream: #ddd9ca;
$c-text-blue: #0f2350;
$c-text-gray: #bfbfbf;
$c-text-dark-gray: #505050;
$c-text-slate: #3e3e3e;

$content-width: 1092px;

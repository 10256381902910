@use "../global" as g;

.c-faq-accordion {
    summary {
        /* 三角アイコン削除 */
        display: block;
    }

    summary::-webkit-details-marker {
        /* 三角アイコン削除 */
        display: none;
    }

    .summary_inner {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 48px;
        @include g.font($size: 18, $kerning: 50);
        padding: 30px 30px 15px;

        @include g.mq(sp) {
            gap: 20px;
            font-size: 14px;
            padding: 40px 15px 15px;
        }

        &::before {
            content: "Q";
            display: block;
            flex-shrink: 0;

            @include g.font($family: g.$font-en, $size: 18, $weight: 500, $kerning: 50);

            @include g.mq(sp) {
                font-size: 14px;
            }
        }
    }

    .icon {
        margin-top: 8px;
        display: block;
        position: relative;
        margin-left: auto;
        width: 10px;
        height: 14px;
        flex-shrink: 0;
        transform: rotate(180deg);
        transform-origin: center 43%;
        transition: transform 0.4s;

        @include g.mq(sp) {
            margin-top: 5px;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    /* is-openedのスタイル */
    details.is-opened .icon {
        transform: rotate(0deg);
    }

    details {
        padding-bottom: 15px;
        border-bottom: 0.5px solid g.$c-border;

        @include g.mq(sp) {
            padding-bottom: 25px;
        }
    }

    // アコーディオンの中身
    .content {
        overflow: hidden;
        // ここにスタイルは記述しない
    }

    .content_inner {
        display: flex;
        gap: 50px;
        padding: 0 30px 15px;
        @include g.font($size: 18, $kerning: 50);

        @include g.mq(sp) {
            gap: 20px;
            padding: 0 15px 15px;
            font-size: 14px;
        }

        &::before {
            content: "A";
            display: block;
            flex-shrink: 0;
            @include g.font($family: g.$font-en, $size: 18, $weight: 500, $kerning: 50);

            @include g.mq(sp) {
                font-size: 14px;
            }
        }
    }
}

@use "../global" as g;

.c-border-button {
    $this: &;
    &--blue {
        #{$this}__inner {
            border: 2px solid g.$c-text-blue;

            &::before,
            &::after,
            & span::before,
            & span::after {
                background-color: g.$c-text-gray;
            }
        }

        #{$this}__text {
            color: g.$c-text-blue;
        }
    }

    &__link {
        display: block;
        width: fit-content;
        position: relative;
    }

    &__inner {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 16px 100px 15px;

        @include g.mq(sp) {
            padding: 16px 40px 15px;
        }

        /* animations */
        &::before {
            content: "";
            position: absolute;
            transition: all 0.1s;
            width: 2px;
            height: 0;
            top: 0;
            left: 0;
            transition-delay: 0.4s;
        }
        &::after {
            content: "";
            position: absolute;
            transition: all 0.1s;
            width: 0;
            height: 2px;
            bottom: 0;
            left: 0;
            transition-delay: 0.3s;
        }
    }

    &__text {
        @include g.font($size: 18, $kerning: 100, $lineheight: 20);

        @include g.mq(sp) {
            font-size: g.calcRem(16px);
        }

        &::before {
            content: "";
            position: absolute;
            transition: all 0.1s;
            width: 2px;
            height: 0;
            bottom: 0;
            right: 0;
            transition-delay: 0.2s;
        }
        &::after {
            content: "";
            position: absolute;
            transition: all 0.1s;
            height: 2px;
            width: 0;
            top: 0;
            right: 0;
            transition-delay: 0.1s;
        }
    }

    &__arrow {
        display: block;
        line-height: 1;
    }
}

// hover
@media (hover: hover) {
    .c-border-button__link:hover {
        .c-border-button__inner::before {
            height: 100%;
            transition: all 0.1s;
        }
        .c-border-button__inner::after {
            width: 100%;
            transition: all 0.1s;
            transition-delay: 0.1s;
        }
        .c-border-button__text::before {
            height: 100%;
            transition: all 0.1s;
            transition-delay: 0.2s;
        }
        .c-border-button__text::after {
            width: 100%;
            transition: all 0.1s;
            transition-delay: 0.3s;
        }
    }
}

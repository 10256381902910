@use "../global" as g;

.c-breadcrumb {
    margin-top: 80px;
    margin-bottom: 20px;
    @include g.content-col-padding;

    @include g.mq(sp) {
        margin-top: 40px;
        margin-bottom: 60px;
    }

    &__wrapper {
        margin: 0 auto;
        max-width: g.$content-width;
        width: 100%;
    }

    &__list {
        @include g.reset-ul;
        display: flex;
        justify-content: flex-end;
    }

    &__item {
        color: g.$c-text-gray;
        display: flex;
        @include g.font($size: 10, $lineheight: 14);

        &:not(:last-of-type) {
            gap: 10px;
            &::after {
                content: "/";
                display: block;
                margin-right: 10px;
            }
        }

        &__link {
            text-decoration: none;
        }

        &--current {
            p {
                max-width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}

@mixin z-map($z-map) {
  $before-index: 1;

  @each $name, $value in $z-map {
    $result-z: null;

    @if $value == auto {
      $result-z: $before-index + 1;
    } @else {
      $result-z: $value;
    }

    $before-index: $result-z;
    #{$name}: $result-z;
  }
}

:root {
  @include z-map(
    (
      --z-header: auto,
    )
  );
}

@use "../global" as g;

.c-page-title {
    @include g.content-col-padding;

    @include g.mq(pc) {
        margin-top: 35px;
        margin-bottom: 95px;
    }

    @include g.mq(sp) {
        margin-top: 67px;
        margin-bottom: 87px;
    }

    &__container {
        max-width: g.$content-width;
        margin: 0 auto;
    }

    img {
        @include g.mq(sp) {
            height: 18px;
        }
    }

    &--onlineshop {
        @include g.mq(sp) {
            margin-bottom: 46px;
        }
    }
}

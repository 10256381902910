@use "../global" as g;

body {
    @include g.font();
    color: g.$c-text;
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
    color: currentColor;
}

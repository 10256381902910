$breakpoints: (
    "sp": "screen and (max-width: 767px)",
    "tab": "screen and (min-width: 768px) and (max-width: 1024px)",
    "tabsp": "screen and (max-width: 1024px)",
    "pc": "screen and (min-width: 768px)",
) !default;

@mixin mq($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

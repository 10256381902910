@use "variable" as v;
@use "media-query" as m;

// calc rem
@function calcRem($size) {
    @return calc(($size / 16px) * 1rem);
}

// font
@mixin font($family: v.$font-ja, $size: 16, $weight: 400, $style: normal, $kerning: 0, $lineheight: 27) {
    font-family: $family;
    font-size: calc((#{$size} / 16) * 1rem);
    font-weight: $weight;
    font-style: $style;
    letter-spacing: calc(#{$kerning} / 1000 * 1em);
    line-height: calc(#{$lineheight} / #{$size});
}

// reset-ul
@mixin reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

// content width
@mixin content-width {
    max-width: calc(v.$content-width + (60px * 2));
    margin: 0 auto;
}

// content padding
@mixin content-col-padding {
    @include m.mq(pc) {
        padding-left: 60px;
        padding-right: 60px;
    }

    @include m.mq(tab) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @include m.mq(sp) {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@use "../global" as g;

.c-hover--opacity {
    transition: all ease 0.25s;

    @media (hover: hover) {
        &:hover {
            opacity: 0.6;
        }
    }
}

.c-hover-waving-animation {
    display: block;
    width: fit-content;

    &__text-wrap {
        position: relative;
        overflow: hidden;
    }

    @media (hover: hover) {
        &:hover {
            .first span,
            .second span {
                transform: translateY(-100%);
                transform: translateY(-100%);
            }
        }
    }

    .first,
    .second {
        display: block;
        span {
            display: inline-block;
            min-width: 5px;
        }
    }

    .second {
        bottom: -100%;
        position: absolute;
    }
}
